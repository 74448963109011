import React from 'react'

import {
  FormInput,
  FormRow,
  FormCol,
  FormContainer,
} from 'components/shared/Forms'
import Button from 'components/shared/Button'
import * as routes from 'constants/routes'

export default () => (
  <FormContainer width="500px">
    <p>
      It's a form. You know what to do.
      <br />
      Or email us at <a href={`mailto:${routes.EMAIL}`}>{routes.EMAIL}</a>
    </p>
    <form
      role="feedback-form"
      className="feedback-form"
      id="feedback-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <FormRow>
        <FormCol>
          <FormInput label="First Name">
            <input
              type="fname"
              name="fname"
              autoFocus
              required
              id="fname"
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormInput label="Email (optional)">
            <input type="email" name="email" id="email" />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FormInput label="Feedback">
            <textarea
              type="feedback"
              name="feedback"
              required
              rows="5"
              id="feedback"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <Button big full>
        Send feedback
      </Button>
    </form>
  </FormContainer>
)
