import React from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import FeedbackForm from 'components/FeedbackPage/FeedbackForm'
import PageTitle from 'components/PageTitle'

const FeedbackPage = () => (
  <div>
    <PageTitle title="Give us Feedback" />
    <FeedbackForm />
  </div>
)

export default ({ location }) => (
  <Layout navbar="main" location={location}>
    <SEO title="Give us Feedback" />
    <FeedbackPage />
  </Layout>
)
